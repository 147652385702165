<!--  -->
<template>
  <div class="GetBackPassword">
    <div class="head">
      <van-nav-bar
        title="修改密码"
        style="background-color: #fbfbfb"
        :left-arrow="true"
        @click-left="onClickLeft"
      />
    </div>
    <div style="padding: 20px">
      <van-form @submit="onSubmit" class="login_form flexcolumn">
        <van-field
          v-model="form.oldpwd"
          type="password"
          :label-width="30"
          name="1"
          label=""
          placeholder="请输入当前密码"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          v-model="form.newPassword"
          type="password"
          :label-width="30"
          name="newPassword"
          label=""
          placeholder="请输入新密码"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          v-model="form.newPassword2"
          type="password"
          :label-width="30"
          name="newPassword2"
          label=""
          placeholder="请重复输入新密码"
          :rules="[{ validator: verifyPassword, message: '重复密码不正确' }]"
        />
        <div style="margin: 40px auto 0; width: 100%">
          <van-button
            round
            block
            type="info"
            class="myButton"
            native-type="submit"
            >确认</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { UpdatePwd } from "@/api";
export default {
  name: "UpdatePassword",
  components: {},
  data() {
    return {
      form: {
        oldpwd: "",
        newPassword: "",
        newPassword2: "",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    async onSubmit() {
      let res=await UpdatePwd({
        uid:this.$store.state.uid,
        oldpwd:this.form.oldpwd,
        newpwd:this.form.newPassword
      })
      res.Code > 0
        ? this.$Toast.success("修改成功")&&this.$router.go(-1)
        : this.$Toast.fail(res.Message);
      this.form = this.$options.data().form;
    },
    verifyPassword(val) {
      return this.form.newPassword == this.form.newPassword2;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
.GetBackPassword {
  width: 100%;
  // padding: 0 60px;
  min-height: 100vh;
  background-color: white;
  h1 {
    font-size: 60px;
    font-weight: bold;
    letter-spacing: 8px;
  }
  .head {
    span {
      width: 45px;
      display: block;
      height: 6px;
      background-color: #000;
    }
  }
  /deep/ .van-cell {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
</style>